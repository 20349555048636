import React, { useState, useEffect } from "react";
import "./About.css";

const About = ({localLinkHandler}) => {


  return (

<>
  {/* Page Content */}
  <div className="articleText-container">
    <h2 className="titleText"><b>About</b> iv4.io</h2>
    <p>
      <b>iv4.io</b> is a side project I developed to keep my (hobbyist) web skills sharp 
      and support my studies in Azure architecture and development.
      <br /><br />
      With many years of networking experience, I often needed a quick way to 
      find my public IP address. I found it overly redundant to search for "What is my IP?" and then click a link.
      Other sites’ URLs were too long to type out, and some sites were just too slow to load.
      <br /><br />
      That’s why I built iv4.io—a fast, lightweight website that instantly 
      returns your public IP address with no ads, tracking, cookies, or pop-ups.
      <br /><br />
      My mission is to continue developing super easy-to-use, lightweight tools that
      admins from all walks of IT can use to make their lives easier. Some tools I have in the works are:
      <br /><br />
      <ul>
        <li>MAC Address Vendor Lookup</li>
        <li>DNS Tools</li>
        <li>Network tools such as ping and traceroute</li>
      </ul>
      <b >
        <u
          style={{ color: "#2a8db4", cursor: "pointer" }}
          onClick={() => localLinkHandler("Roadmap")}
          
        >
          Click Here
        </u>
      </b>{" "}
      to see the roadmap and learn about upcoming features!
      <br /><br />
      
      <h3>Have Suggestions?</h3>
      If you have ideas for new tools, features, articles, or improvements, feel free to send me an email anytime!
      <br /><br />
      <div className="code-box">
        <p className="code-text">
          hello@iv4.io
        </p>
        <br /><br />
        <a
          className="flatIco-Contribution"
          href="https://www.flaticon.com/free-icons/ip-location"
          title="ip location icons"
        >
          IP location icons created by Freepik - Flaticon
        </a>
      </div>
    </p>
  </div>
</>


  );
};

export default About;
