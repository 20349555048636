import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentOptions from "./paymentOptionsList";
import "./donate.css";

const About = ({localLinkHandler}) => {


    return (

        <>
        {/* Page Content */}
        <div className="articleText-container">
          <h2 className="titleText"><b>Donate</b> to iv4.io</h2>
          
          <p className="about-text">
            <b>iv4.io</b> is a web-based tool stack created for the sysadmin community—simple, 
            user-friendly, and accessible from anywhere!
          </p>
          <p className="about-text">
            I built and continue to develop this site in my free time, hosting it on 
            my personal Microsoft Azure tenant. I fund iv4.io from my own resources, and the site generates no revenue.
          </p>
          <p className="about-text">
            I’m committed to adding new features and performance enhancements. 
            If you support the mission of iv4.io and want to see it grow, please consider donating.
          </p>
          <p className="about-text">
            <b>
              <u
                style={{ color: "#2a8db4", cursor: "pointer" }}
                onClick={() => localLinkHandler("FeatureDetails")}
              >
                Click Here
              </u>
            </b>{" "}
            to view the roadmap and discover which upcoming 
            features and improvements your donation will support.
          </p>
      
          <div className='pageBreak'></div>
      
          <div className="stripePaymentContainer">
            <div className="donation-security">
              <span className="security-icon">🔒</span>
              <span className="security-text">Your donation is secure and encrypted.</span>
            </div>
            <stripe-buy-button
              buy-button-id="buy_btn_1R2xjBD7fIRTKPfTkVMFAQPF"
              publishable-key="pk_live_51R2hiOD7fIRTKPfTLFii2WTx34NYnKeGAy23BXtEgNBKe7RYTBHKk07ZfBzvKB0X8CSLpb3uc6a5zdrXaIsQm9Ak00oGwvYB2p"
            >
            </stripe-buy-button>
          </div>
      
          <h2 className="titleText"><b>Contribute</b> to iv4.io</h2>
          
          <p className="donate-text">
            Donations aren’t the only way to support iv4.io!
          </p>
          <p className="donate-text">
            Developers are invited to contribute code and suggest new features. If you have ideas or would like to help with development, please reach out via the email below.
          </p>
          <p className="donate-text">
            Sysadmins can also share their suggestions for useful tools or features. Help spread the word about iv4.io within your network and on social media.
          </p>
          <p className="donate-text">
            Even simply using the site and sending a kind note to let me know you find it useful makes a difference.
          </p>
          <p className="donate-text">
            Please don't hesitate to contact me at any time.
          </p>
          <div className="pageBreak"></div>
          <h3>Email</h3>
          <p className="donate-text">
            All inquiries are welcome!
          </p>
          <div className="code-box">
            <p className="code-text">
              hello@iv4.io
            </p>
          </div>
        </div>
      </>
      



    );
};

export default About;
