import React, { useState, useEffect } from "react";

const FeatureDetails = ({localLinkHandler}) => {


  return (
<>
  {/* Page Content */}
  <div className="articleText-container">
    <h2 className="titleText">Features and Enhancements</h2>
    <p>
      Let's talk about some of the features and enhancements that are planned for the future of iv4.io.
      This page will list upcoming features in the order they are planned to be developed.
      <br /><br />
      ALL donations go towards the development of these features, and the hosting of iv4.io.
      <br /><br />
      <b>
      <u
                style={{ color: "#2a8db4", cursor: "pointer" }}
                onClick={() => localLinkHandler("Donate")}
              >
                Click Here
              </u>
            </b>{" "}
            to donate!
    </p>
          <div className="pageBreak"></div>
    <h2>1 - Hosting Plan Upgrade</h2>
    <p>
      This app is hosted with Azure app services and currently has a free plan.
      This plan is designed for development and testing, and is not suitable for production use.
      <br /><br />
      While I'm getting by now with the free plan, I would like to upgrade to a paid plan 
      to ensure that the app is always available and responsive.
      <br /><br />
      As this site becomes more popular, it will be at risk of performance degradation and downtime due to the free plan limitations.
    </p>

    <div className="pageBreak"></div>

    <h2>2 - Additional IP Details</h2>
    <p>
      IP details will start to include additional information like:
   
      <ul>
        <li>Internet Service Provider (ISP)</li>
        <li>Organization or Business</li>
        <li>Time Zone</li>
        <li>IP Type (Static or Dynamic)</li>
        <li>IP Usage (Residential, Business, or Datacenter)</li>
      </ul>
      In many cases, these minor details can be useful for troubleshooting network issues or identifying potential security threats.
    </p>

    <div className="pageBreak"></div>

    <h2>3 - Network Tools</h2>
    <p>
      Is it the firewall? Is it me? Is it the server? Use network tools like you would on Windows or Linux,
      but with the convenience of knowing your problem symptoms aren't due to a firewall or software issue.
      <br /><br />
      Not to mention, using tools like {"\u00a0"} 
        <p className='code-box code-text'>ping</p> {"\u00a0"}, {"\u00a0"}
        <p className='code-box code-text'>tracert</p>{"\u00a0"}, {"\u00a0"}
        <p className='code-box code-text'>nslookup</p>{"\u00a0"}, {"\u00a0"}
         all in a web browser would be a huge plus on its own!
        <br></br>
        <br></br>
      In many cases, these minor details can be useful for troubleshooting network issues or identifying potential security threats.
    </p>

    
    <div className="pageBreak"></div>

    <h3>Suggestions?</h3>
    If you have any suggestions for tools, features, articles, or anything else, send me an email anytime!
    <br /><br />
    <div className="code-box">
      <p className="code-text">hello@iv4.io</p>
    </div>
  </div>
</>

  );
};

export default FeatureDetails;
