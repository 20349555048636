import React, { useEffect, useState } from 'react';
import ipAddressImage from '../images/ip-address.png'


const IpData = () => {

    const [ip, setIp] = useState(null);
    const formattedIp = String(ip).replace(/\./g, ' . ');
    const inDev = true;
    const url = inDev ? 'https://ipdataapi.azurewebsites.net/api/HttpTrigger1?code=y95qxV28_z9dHtQQVy5MGN4T0qR8NnxVD0R6qHOj8X9vAzFuWWKEYA==' : '';
    const [location, setLocation] = useState('Fetching');
    const [ipType, setIpType] = useState('Fetching')
    const [tooltipContent, setTooltipContent] = useState("Copy");
    const [dataReceipt, setDataReceipt] = useState(false)

    useEffect(() => {
        fetch(url, {
            /*  headers: {
                'ngrok-skip-browser-warning': 'skip-browser-warning',
              },*/
        })
            .then((response) => response.json())
            .then((data) => {
                setIp(data.ip);
                setLocation(data.location);
                setIpType(data.ipType)
                setDataReceipt(true)
                console.log(data)
            });
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(ip).then(() => {
            setTooltipContent("Copied!");
            // Reset tooltip after some time (e.g., 2 seconds)
            setTimeout(() => setTooltipContent("Copy"), 2000);
        });
    };


    return (
        <>


            <div className="ipContainer">
                {!ip ? '' : <div className='titleBox'><p>Your IP Address</p></div>}

                <div className="ipDisplayContainer">

                    <img id="ipSymbol" width="25px" height="25px" src={ipAddressImage} alt="IP Address" />

                    <h1 className='ipAddress'>
                        {ip ? formattedIp : <div className="spinner"></div>}
                    </h1>
                    <span
                        onClick={copyToClipboard}
                        className="material-symbols-outlined"
                        data-tooltip={tooltipContent}
                    >
                        content_copy
                    </span>
                </div>
                {dataReceipt ?

                    <div className='ipDetail location'>
                        <table>
                            <tbody>
                            <tr>
                                <td><b>City</b></td>
                                <td>{location.city}</td>
                            </tr>
                            <tr>
                                <td><b>State</b></td>
                                <td>{location.region}</td>
                            </tr>
                            <tr>
                                <td><b>Country</b></td>
                                <td>{location.country}</td>
                            </tr>
                            </tbody>
                        

                        </table>
                    </div>

                    :
                    null
                }

            </div>
    
        </>

    )
}


export default IpData