import React, { useEffect, useState } from 'react';
import { AiTwotoneHeart } from "react-icons/ai";
import './App.css';
import './pageComponents/ipData.css';
import './blogTextFormatting.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './pageComponents/NavBar.js';
import IpData from './pageComponents/ipData.js';
import About from './pageComponents/about.js';
import Donate from './pageComponents/donate.js';
import Home from './pageComponents/ipData.js';
import logo from './images/ipPin.png'
import FeatureDetails from './pageComponents/featureDetails.js';

function App() {
  const [selectedOption, setSelectedOption] = useState('MissionStatement');
  const [isOpen, setIsOpen] = useState(false);

  // Initialize dark mode state so that dark mode is enabled by default
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    if (savedMode === null) {
      localStorage.setItem("darkMode", "enabled");
      return true;
    }
    return savedMode === "enabled";
  });

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem("darkMode", newMode ? "enabled" : "disabled");
  };

  // Apply Dark Mode
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  useEffect(() => {
    console.log("Option in IpData:", selectedOption);
  }, [selectedOption]);

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  const handleNavBarSelection = (value) => {
    // Update the selected menu item based on the value received from NavBar
    setSelectedOption(value);
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'Home':
        return <IpData />;
      case 'About':
        return <About
          localLinkHandler={handleNavBarSelection}
        />;
      case 'Donate':
        return <Donate 
        localLinkHandler={handleNavBarSelection}
        />;
      case 'FeatureDetails':
        return <FeatureDetails
        localLinkHandler={handleNavBarSelection}
        />;

      case 'Roadmap':
        return <FeatureDetails 
        localLinkHandler={handleNavBarSelection}
        />;
      // Add more cases as needed
      default:
        return <Home />; // Default if no case matches
    }
  };



  return (
    <>
      <div className={`App ${darkMode ? "dark-mode" : ""} ${isOpen ? "sidebar-open" : ""}`}>
        {/* Full-width Header */}
        <header className="header">
          <div className="header-left">
            <div className="logo-container">
              <h1
                className="siteTitle"
                onClick={() => window.location.href = "/"}
              >
                iv4.io
              </h1>
              <img
                src={logo}
                className="logo"
                onClick={() => setSelectedOption("Home")}
                alt="Logo"
              />
            </div>
          </div>
          <div className="header-center specialHeader">
            <p className="hide-on-mobile">
              A simple and free tool to find your public IP address
            </p>
          </div>
          <div className="header-right">
            <button className="dark-mode-toggle" onClick={toggleDarkMode}>
              {darkMode ? "☀️ Light" : "🌙 Dark"}
            </button>
          </div>
        </header>

        {/* Container for the rest of the content */}
        <div className="container">
          {/* NAV BAR: Passing isOpen, toggleSidebar, and onNavItemClick */}
          <NavBar
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
            onNavItemClick={handleNavBarSelection} />

          {/* RENDERED CONTENT */}
          <div className="renderedContent">
            {renderContent()}

          </div>
        </div>
        <div className='footer'>
          {/* FOOTER */}
          <footer className="footer2">
            <p>iv4.io | The Future of Admin Tools <p className="donateLink hoverEffect-Text" onClick={() => setSelectedOption("Donate")}>
              Donate  <AiTwotoneHeart />
            </p>


            </p>

          </footer>


        </div>

      </div>

    </>
  );


}

export default App;
