import React from "react";
import { FaAngleDoubleRight, FaAngleDoubleLeft, faHeart } from "react-icons/fa"; 


import "./navBar.css";

const NavBar = ({ isOpen, toggleSidebar, onNavItemClick }) => {
    
    // Function to close sidebar after a short delay
    const handleMouseLeave = () => {
        setTimeout(() => {
            toggleSidebar(false);
        }, 100); // Adjust delay as needed
    };

    return (
      <>
        {/* Sidebar */}
        <div 
          className={`sidenav ${isOpen ? "open" : ""}`} 
          onMouseLeave={handleMouseLeave} /* Collapse when mouse leaves */
        >   
          <div className="menu-items">
          <div className="nav-item" onClick={() => onNavItemClick("Home")}>
              <p>Your IP</p>
            </div>
            <div className="nav-item" onClick={() => onNavItemClick("About")}>
              <p>About</p>
            </div>
            <div className="nav-item" onClick={() => onNavItemClick("Donate")}>
              <p>Donate</p>
            </div>   
            <div className="nav-item" onClick={() => onNavItemClick("FeatureDetails")}>
              <p>Roadmap</p>
            </div>
          </div>  
        </div>
      
        {/* Expand Button - Always Visible */}
        <div className="expandNavMenu nav-item" onClick={toggleSidebar}>
          {isOpen ? <FaAngleDoubleLeft className="nav-icon" /> : <FaAngleDoubleRight className="nav-icon" />}
        </div>
      </>
    );
};

export default NavBar;
