import React from 'react';
import { FaCreditCard } from 'react-icons/fa';
import { SiApplepay } from 'react-icons/si'; // Apple Pay logo

const PaymentOptions = () => {
  return (
    <section className="payment-options" style={styles.section}>
      <div style={styles.optionsContainer}>
        <div style={styles.option}>
          <FaCreditCard size={28} style={styles.icon} />
          <span style={styles.label}><p>Credit Card</p></span>
        </div>
        <div style={styles.option}>
          <SiApplepay size={28} style={styles.icon} />
          <span style={styles.label}><p>Apple Pay</p></span>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: {
    textAlign: 'center',
    padding: '20px'
  },
  header: {
    marginBottom: '20px'
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px'
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer'
  },
  icon: {
    marginBottom: '10px'
  },
  label: {
    fontSize: '16px',
    fontWeight: '500'
  }
};

export default PaymentOptions;
